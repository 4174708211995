import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.three-columns-block');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 1.1,
        spaceBetween: 16,

        breakpoints: {
          768: {
            slidesPerView: 2.1,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: 'auto',
            spaceBetween: 16,
          },
        },
      });
    });
  }
});
